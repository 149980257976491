import React from 'react'

const Footer = () => {
  return (
    <footer>
        <span>Copyright Pizza © 2022</span>
    </footer>
  )
}

export default Footer