import { ADD_ORDER, ADD_PIZZA_TO_ORDER, DELETE_ORDER, COLLECT_ORDER } from "./types"

export const addOrder = (id) => {
    return {
        type: ADD_ORDER,
        payload: {
            id,
            cart: {},
            total: 0,
            dateOrder: Date.now(),
            datePay: null,
            paid: false
        }
    }
}

export const addPizzaToOrder = (idPizza, order) => {
    return {
        type: ADD_PIZZA_TO_ORDER,
        payload: {
            idPizza,
            order
        }
    }
}

export const deleteOrder = (id) => {
    return {
        type: DELETE_ORDER,
        payload: {
            id
        }
    }
}

export const collectOrder = (id) => {
    return {
        type: COLLECT_ORDER,
        payload: {
            id
        }
    }
}