import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Layout = (props) => {
  return (
    <>
      <Header></Header>
      <div id="content-wrap">
        <div className='container'>
          {props.children}
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default Layout