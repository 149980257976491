import React from 'react'
//reactrouter
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
//components
import ListOrders from './elements/ListOrders';
import Order from './elements/Order';
import Pay from './elements/Pay';
import NotFound from './elements/NotFound';
import Home from './Home';


const Root = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Home />} />
                <Route path='/order/:orderId' element={<Order />} />
                <Route path='/listorders' element={<ListOrders />} />
                <Route path='/pay' element={<Pay />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Root