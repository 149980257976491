import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addOrder } from './actions';
import Layout from './components/Layout';
import { CheckOutlined, FireOutlined, ShoppingCartOutlined } from '@ant-design/icons';

const Home = () => {
  //navigate
  const navigate = useNavigate();

  // //on récupère le tableau Orders du state global via le Selector:
  // const orders = useSelector(state => state.orders);

  // création dispatch pour appeller nos actions:
  const dispatch = useDispatch();

  // create new order and redirect on it
  const newOrder = () => {
    let id = `CMD-${Date.now()}`;
    //ajout order dans le state global
    dispatch(addOrder(id));
    //redirect
    navigate(`/order/${id}`);
  }

  return (
    <Layout>
      <div className='menu-wrapper'>
        <div onClick={() => newOrder()} className="menu-item bg-primary">
          <span><ShoppingCartOutlined style={{ fontSize: '7rem', marginBottom: "1rem" }} /></span>
          <hr />
          <span>Nouvelle commande</span>
          <p onFocus={() => newOrder()}>
            Créer et enregistrer une nouvelle commande
          </p>
        </div>
        <div onFocus={() => navigate(`/listorders`)} onClick={() => navigate(`/listorders`)} className="menu-item bg-secondary">
          <span><FireOutlined style={{ fontSize: '7rem', marginBottom: "1rem" }} /></span>
          <hr />
          <span>Commande en cours</span>
          <p>
            Voir le détail des commandes en cours
          </p>
        </div>
        <div onFocus={() => navigate(`/pay`)} onClick={() => navigate(`/pay`)} className="menu-item bg-tertiary">
          <span><CheckOutlined style={{ fontSize: '7rem', marginBottom: "1rem" }} /></span>
          <hr />
          <span>Paiement commande</span>
          <p>
            Encaisser une commande
          </p>
        </div>
      </div>

    </Layout>
  )
}

export default Home