import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { addPizzaToOrder } from '../actions';
import Layout from '../components/Layout';
import OrderDetail from '../components/OrderDetail';

const Order = () => {
  //on récupère params pour avoir l'id dans l'URL
  let params = useParams();
  // orders du stateglobal
  const orders = useSelector(state => state.orders)
  // pizzas du stateglobal
  const pizzas = useSelector(state => state.pizzas)
  //recuperation dispatch:
  const dispatch = useDispatch();
  //commande en cours de consultation:
  let order = orders[orders.findIndex(order => order.id === params.orderId)]

  //ajout pizza à une commande:
  const handlePizzaClick = (pizza) => {
    dispatch(addPizzaToOrder(pizza.id, order));
    // alert("pizza:"+ pizza.nom);
  }

  const pizzasElement = pizzas.map(pizza => {
    return (
      <div key={pizza.id} className='pizza' onClick={() => handlePizzaClick(pizza)}>
        <img src={pizza.url} alt={pizza.nom} />
        <div>
          <h3>{pizza.nom}</h3>
          <span>{pizza.prix} €</span>
        </div>
      </div>
    )
  })

  //render
  return (
    <Layout>
      <div className='order'>
        {/* <h1>Order: {params.orderId}</h1> */}
        <div className='pizzas'>
          {pizzasElement}
        </div>
        <div className='orderDetails'>
          <OrderDetail order={order} />
        </div>
      </div>
    </Layout>

  )
}

export default Order