import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteOrder } from '../actions'
import Layout from '../components/Layout'

const ListOrders = () => {
  // orders du stateglobal
  const orders = useSelector(state => state.orders)
  const dispatch = useDispatch();

  const ordersElements = orders.map(order => {
    const orderPaidTrue = <><td className='actionOrder'><Link to={`/order/${order.id}`} ><EditOutlined style={{ fontSize: '2rem', color: '#08c' }} /></Link></td><td className='actionOrder' onClick={() => dispatch(deleteOrder(order.id))}><DeleteOutlined style={{ fontSize: '2rem', color: '#bf4080' }} /></td></>;
    return (
      <tr key={order.id}>
        <td>{order.id}</td>
        <td>{order.total}€</td>
        {order.paid ? <td className='paid'>Réglé</td> : <td className='notPaid'>En attente de paiement</td>}
        {order.paid ? <><td></td><td></td></> : orderPaidTrue}
      </tr>
    )
  })
  return (
    <Layout>
      <div className='listOrder'>
        <h2>Liste des commandes</h2>
        <table>
          <thead>
            <th>N°</th>
            <th>Total commande</th>
            <th>Status commande</th>
            <th colSpan="2" className='actionOrder'>Actions</th>
          </thead>
          <tbody>
            {ordersElements.length > 0 ? ordersElements : <tr><td colSpan="100%">Aucune commande</td></tr>}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default ListOrders