import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../images/logo.png"

const Header = () => {
  return (
    <div className='container'>
      <header>
        <div>
          <Link to={"/"}>
            <img src={logo} alt="logo enseigne" />
          </Link>
        </div>
      </header>
    </div>
  )
}

export default Header