import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { collectOrder } from '../actions';

const OrderDetail = ({ order, setPay }) => {
    // let dateOrder = Date(order.dateOrder).toDateString;
    // let datePaid = Date(order.datePay).toDateString;

    // pizzas du stateglobal
    const pizzas = useSelector(state => state.pizzas)
    const dispatch = useDispatch();

    let pizzasChoice = Object.keys(order.cart).map(keyPizza => {
        // order.cart[keyPizza] //quantité
        let indexP = pizzas.findIndex(p => p.id.toString() === keyPizza);
        return <div key={`IT-${keyPizza}`}>
            <div className='pizzaChoice'>
                <span>{pizzas[indexP].nom}</span>
                <span>{Math.round((pizzas[indexP].prix * order.cart[keyPizza]) * 100) / 100}€</span>
                
            </div>
            <span>({order.cart[keyPizza]} x {pizzas[indexP].prix}€)</span>
            <hr />
        </div>
    })

    return (
        <div className='orderDetails'>
            <h3>Détail de la commande n°{order.id}</h3>
            {/* <p>Date commande: {dateOrder}</p> */}
            {/* <p>Date payé: {datePaid}</p> */}
            {pizzasChoice.length > 0 ? pizzasChoice : <p>Aucune pizza</p>}
            <div className='optionOrder'>
                <button>Soit un total de: <span>{order.total}€</span> </button>
                {setPay && !order.paid ? <button className='paid' onClick={() => dispatch(collectOrder(order.id))}>Encaisser la commande</button> : ""}
            </div>
        </div>
    )
}

export default OrderDetail