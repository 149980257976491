import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Layout from '../components/Layout'
import OrderDetail from '../components/OrderDetail'

const Pay = () => {
  const [selectedOrder, setSelectedOrder] = useState("");

  // orders du stateglobal
  const orders = useSelector(state => state.orders);

  const order = orders.find(o => o.id === selectedOrder)
  // console.log(order);
  // const ordersSelect = orders.filter(o => o.paid !== true).map((o) => {
  const ordersSelect = orders.map((o) => {

    return (
      <option key={o.id} value={o.id}>{o.id} - {o.paid? "ENCAISSE":"NON ENCAISSE"}</option>
    )
  })

  return (
    <Layout>
      <div className='choiceOrder'>
        <label>Veuillez choisir une commande : <br />
          <select id="selectOrder" value={selectedOrder} onChange={(e) => setSelectedOrder(e.target.value)}>
            <option defaultValue>--- selectionner ---</option>
            {ordersSelect}
          </select>
        </label>
      </div>
      {order ? <OrderDetail order={order} setPay={true} /> : <div className='choiceOrder'>Aucune commande</div>}
    </Layout>
  )
}

export default Pay