import { ADD_ORDER, ADD_PIZZA_TO_ORDER, COLLECT_ORDER, DELETE_ORDER } from "../actions/types";


const initialState = {
    orders: [
        // order de test
        // {
        //     id: "1", // id qu'on créera par un Date.Now()
        //     cart: { //panier d'éléments
        //         2: 5, //id de l'item (ex: 2 => 4 fromage)
        //         1: 2 // 2 x pizzas marguerita
        //     },
        //     total: 0, // se calcul à chaque ajout via ADD_ITEM_TO_ORDER
        //     dateOrder: 1655373638, // date en timestamp via Date.Now() à l'appel de addOrder()
        //     datePay: 1655375738, //date paiement Date.Now() via OrderPaid() et mets aussi paid à TRUE
        //     paid: true
        // }
    ],
    pizzas: [
        {
            id: 1,
            nom: "Marguerita",
            prix: 7.99,
            url: "/img/pizzas/marguerita.jpg"
        },
        {
            id: 2,
            nom: "Quatro fromagi",
            prix: 10.99,
            url: "/img/pizzas/4-fromages.jpg"
        },
        {
            id: 3,
            nom: "Pepperoni",
            prix: 11.99,
            url: "/img/pizzas/pepperoni.jpg"
        },
        {
            id: 4,
            nom: "Reine",
            prix: 13.99,
            url: "/img/pizzas/reine.jpg"
        },
        {
            id: 5,
            nom: "Saumon",
            prix: 13.99,
            url: "/img/pizzas/reine.jpg"
        }
    ]
}

export default function rootReducer(state = initialState, action) {
    const copyOrders = [...state.orders];


    switch (action.type) {
        case ADD_ORDER:
            copyOrders.push(action.payload);
            // console.log("push de l'id " + action.payload.id);
            // console.log(copyOrders);
            return {
                ...state,
                orders: copyOrders
            }
        case ADD_PIZZA_TO_ORDER:
            const copyOrder = { ...action.payload.order }
            const indexOrder = copyOrders.findIndex(order => order.id === copyOrder.id)

            const foundItem = Object.keys(copyOrder.cart).find(keyPizza => {
                return (keyPizza === action.payload.idPizza.toString())
            })
            if (foundItem !== undefined) {
                copyOrder.cart[action.payload.idPizza]++
                copyOrder.total = Math.round((copyOrder.total + state.pizzas[state.pizzas.findIndex(pizza => pizza.id === action.payload.idPizza)].prix) * 100) / 100
            } else {
                copyOrder.cart[action.payload.idPizza] = 1;
                copyOrder.total = Math.round((copyOrder.total + state.pizzas[state.pizzas.findIndex(pizza => pizza.id === action.payload.idPizza)].prix) * 100) / 100
            }

            //on remplace cart par la copie
            copyOrders[indexOrder] = copyOrder;

            return {
                ...state,
                orders: copyOrders
            }
        case DELETE_ORDER:
            // alert("ok delete:"+action.payload.id);
            const indexToDelete = copyOrders.findIndex(order => order.id === action.payload.id);
            copyOrders.splice(indexToDelete, 1);
            return {
                ...state,
                orders: copyOrders
            }
        case COLLECT_ORDER:
            const indexToCollect = copyOrders.findIndex(order => order.id === action.payload.id);
            copyOrders[indexToCollect].paid = true;
            return {
                ...state,
                orders: copyOrders
            }
        default:
            return state;
    }
}